/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import PropTypes from '../utils/PropTypes';

const propTypes = {
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    children: PropTypes.node,
    partiallyActive: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

const defaultProps = {
    activeClassName: null,
    children: null,
    className: null,
    partiallyActive: false,
};

const INTERNAL_LINK_PATTERN = /^\/(?!\/)/;
const FILE_LINK_PATTERN = /\.[0-9a-z]+$/i;
const UNDERLINE_CLASS_PATTERN = /(^|\s)underline-\S+/;
const DECORATION_CLASS_PATTERN = /(^|\s)decoration-((?!(slice|clone))\S)+/;

const Link = ({
    activeClassName,
    children,
    className,
    partiallyActive,
    to,
    ...rest
}) => {
    const classNames = classnames(
        className,
        {
            'decoration-font': !DECORATION_CLASS_PATTERN.test(className),
            'underline-widest': !UNDERLINE_CLASS_PATTERN.test(className),
        },
    );

    if (!INTERNAL_LINK_PATTERN.test(to)) {
        return (
            <a href={to} target="_blank" rel="noopener noreferrer" className={classNames} {...rest}>
                {children}
            </a>
        );
    }

    if (FILE_LINK_PATTERN.test(to)) {
        return (
            <a href={to} className={classNames} {...rest}>
                {children}
            </a>
        );
    }

    const linkWithoutHost = to.includes('minify-css.com') ? to.split('minify-css.com')[1] : to;

    return (
        <GatsbyLink
            to={linkWithoutHost}
            activeClassName={activeClassName}
            className={classNames}
            partiallyActive={partiallyActive}
            {...rest}
        >
            {children}
        </GatsbyLink>
    );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
