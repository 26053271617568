import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Link from '../components/Link';

import PackageJson from '../../package.json';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "icon.svg"}) {
        publicURL
      }
    }
  `);

  return (
    <header className="bg-blue-800 py-4 text-white">
      <div className="container-lg">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div>
            <Link className="flex items-center block no-underline" to="/">
              <img src={data.logo.publicURL} className="h-8 w-8 border border-white shrink-0" alt="Minify CSS logo" />
              <div className="ml-3">
                <span className="block tracking-wide font-display font-semibold">
                  Minify CSS Online
                </span>
                {' '}
                <span className="text-gray-200 text-xs block">
                  {`v${PackageJson.version}`}
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
