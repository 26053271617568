import React from 'react';
import Link from '../components/Link';

const Footer = () => (
  <footer className="bg-blue-800 text-white">
    <div className="container-lg">
      <div className="flex items-center justify-between gap-4 py-4">
        <div>
          <small className="text-xs">&copy; minify-css.com</small>
          {' '}
          <Link className="text-xs hover:underline" to="/privacy-policy/">privacy policy</Link>
        </div>
        <div>
          <small className="text-xs">
            Developed by
          </small>
          {' '}
          <Link className="text-xs hover:underline" to="https://pratsko.dev/">Oleh Pratsko</Link>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
